import { Howl } from "howler";
import { ambient } from "audiate";

import voices from "../audio";

const el = document.getElementById("root");

const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
const sample = xs => xs[Math.floor(Math.random() * xs.length)];

const on = () =>
  new Promise(resolve => {
    el.innerHTML = `<span class="Logo"></span>`;

    new Howl({
      src: sample(voices),
      autoplay: true,
      onend: () => wait(500).then(resolve)
    });
  });

const off = () =>
  new Promise(resolve => {
    el.innerHTML = "";
    wait(1000).then(resolve);
  });

const run = on => {
  on()
    .then(off)
    .then(() => run(on));
};

run(on);
ambient();
