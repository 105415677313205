import Aditi from "./Aditi.mp3";
import Amy from "./Amy.mp3";
import Astrid from "./Astrid.mp3";
import Bianca from "./Bianca.mp3";
import Brian from "./Brian.mp3";
import Carla from "./Carla.mp3";
import Carmen from "./Carmen.mp3";
import Chantal from "./Chantal.mp3";
import Conchita from "./Conchita.mp3";
import Cristiano from "./Cristiano.mp3";
import Emma from "./Emma.mp3";
import Enrique from "./Enrique.mp3";
import Ewa from "./Ewa.mp3";
import Filiz from "./Filiz.mp3";
import Geraint from "./Geraint.mp3";
import Giorgio from "./Giorgio.mp3";
import Gwyneth from "./Gwyneth.mp3";
import Hans from "./Hans.mp3";
import Ivy from "./Ivy.mp3";
import Jacek from "./Jacek.mp3";
import Jan from "./Jan.mp3";
import Joanna from "./Joanna.mp3";
import Joey from "./Joey.mp3";
import Justin from "./Justin.mp3";
import Karl from "./Karl.mp3";
import Kendra from "./Kendra.mp3";
import Kimberly from "./Kimberly.mp3";
import Liv from "./Liv.mp3";
import Lotte from "./Lotte.mp3";
import Lucia from "./Lucia.mp3";
import Mads from "./Mads.mp3";
import Maja from "./Maja.mp3";
import Marlene from "./Marlene.mp3";
import Mathieu from "./Mathieu.mp3";
import Matthew from "./Matthew.mp3";
import Maxim from "./Maxim.mp3";
import Mia from "./Mia.mp3";
import Miguel from "./Miguel.mp3";
import Mizuki from "./Mizuki.mp3";
import Naja from "./Naja.mp3";
import Nicole from "./Nicole.mp3";
import Raveena from "./Raveena.mp3";
import Ricardo from "./Ricardo.mp3";
import Ruben from "./Ruben.mp3";
import Russell from "./Russell.mp3";
import Salli from "./Salli.mp3";
import Seoyeon from "./Seoyeon.mp3";
import Takumi from "./Takumi.mp3";
import Tatyana from "./Tatyana.mp3";
import Vicki from "./Vicki.mp3";
import Zeina from "./Zeina.mp3";
import Zhiyu from "./Zhiyu.mp3";

export default [
  Aditi,
  Amy,
  Astrid,
  Bianca,
  Brian,
  Carla,
  Carmen,
  Chantal,
  Conchita,
  Cristiano,
  Emma,
  Enrique,
  Ewa,
  Filiz,
  Geraint,
  Giorgio,
  Gwyneth,
  Hans,
  Ivy,
  Jacek,
  Jan,
  Joanna,
  Joey,
  Justin,
  Karl,
  Kendra,
  Kimberly,
  Liv,
  Lotte,
  Lucia,
  Mads,
  Maja,
  Marlene,
  Mathieu,
  Matthew,
  Maxim,
  Mia,
  Miguel,
  Mizuki,
  Naja,
  Nicole,
  Raveena,
  Ricardo,
  Ruben,
  Russell,
  Salli,
  Seoyeon,
  Takumi,
  Tatyana,
  Vicki,
  Zeina,
  Zhiyu
];
